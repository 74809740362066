@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

body {
  font-family: 'Roboto', monospace;
  font-size: 0.9rem;
  font-weight: 300;
}

header {
  font-family: 'Source Code Pro', monospace;
  background-color: rgb(246, 246, 246);
  font-size: 1rem;
}

header small {
  font-size: 0.6rem;
  margin-top: 0.4rem;
}

header,
main {
  padding: 1.2rem;
}

menu {
  padding: 0;
  overflow: auto;
}

menu .menu-item:hover {
  background-color: rgb(246, 246, 246);
}

menu label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0.5rem 0.8rem;
  cursor: pointer;
}

menu img {
  width: 18px;
  margin-right: 0.5rem;
}

menu input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
  margin: .4rem;
}

menu input[type="radio"]:checked + label {
  background-color: rgb(240, 240, 240);
}

footer {
  font-family: 'Source Code Pro', monospace;
  padding: 0.4rem 1.2rem;
  border-top: 1px solid rgb(232, 232, 232);
  font-size: 0.65rem;
  color: rgb(75, 75, 75);
  font-weight: 300;
}

footer ul {
  list-style: none;
  margin: 0.4rem;
  padding: 0;
  text-align: center;
}

.text-grey {
  color: rgb(180, 180, 180);
}
