body {
  margin: 0;
  padding: 0;
}

#root {
  display: grid;

  grid-template-areas:
      "header header header"
      /* "nav content side" */
      "menu content content"
      "footer footer footer";

  grid-template-columns: 200px 1fr 200px;
  grid-template-rows: auto 1fr auto;
  /* grid-gap: 10px; */

  height: 100vh;
}

header {
  grid-area: header;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

nav {
  grid-area: nav;
  margin-left: 0.5rem;
}

menu {
  grid-area: menu;
  margin: 0;
  margin-right: 0.5rem;
}

main {
  grid-area: content;
  display: flex;
  flex-flow: column;
  /* align-items: center; */
}

aside {
  grid-area: side;
  margin-right: 0.5rem;
}

footer {
  grid-area: footer;
}

footer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  #root {
    grid-template-areas:
        "header"
        "nav"
        "menu"
        "content"
        "side"
        "footer";

    grid-template-columns: 1fr;
    grid-template-rows:
        auto /* Header */
        minmax(75px, auto) /* Nav */
        1fr /* Content */
        minmax(75px, auto) /* Sidebar */
        auto; /* Footer */
  }

  nav, aside {
    margin: 0;
  }
}
